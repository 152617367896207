import React from "react";
import Navigation from "../components/navigation";
import "../styles/contact.css";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Prices = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>
      
      <div className="contact-content">
        <p>
          {" "}
          <strong>Please note that our office hours are:</strong>
        </p>
        <p>
          Monday - Friday: 8am - 6pm
          <br />
          Weekends by appointment only
        </p>

        <p>
          <strong>Contact information:</strong>
        </p>
        <p>
          +61 479 088 029
          <br />
          office@noosaairbnbapprovals.com.au
        </p>
      </div>

      <button className="cta-button" onClick={handleSubmit}>
        Get Your Approval Here
      </button>
    </div>
  );
};

export default Prices;

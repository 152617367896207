import React from 'react';
import Navigation from '../components/navigation'; 
import '../styles/portfolio.css';
import logo from "../assets/logo.png";
import slider1 from "../assets/slider1.jpg";
import slider2 from "../assets/slider2.jpg";
import slider3 from "../assets/slider3.jpg";
import slider4 from "../assets/slider4.jpg";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Portfolio = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <Navigation /> 

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <div className="portfolio-header">
        <h1>Our Portfolio</h1>
      </div>

      <div className="portfolio-content">
        <p>Explore our recent projects and successful case studies:</p>

        <div className="portfolio-items">
          <Carousel showThumbs={true} autoPlay infiniteLoop showArrows={false}>
            <div>
                <img src={slider1} />
                <p className="legend">Portfolio 1</p>
            </div>
            <div>
                <img src={slider2} />
                <p className="legend">Portfolio 2</p>
            </div>
            <div>
                <img src={slider3} />
                <p className="legend">Portfolio 3</p>
            </div>
            <div>
                <img src={slider4} />
                <p className="legend">Portfolio 4</p>
            </div>
          </Carousel>

          {/* <div className="portfolio-item">
            <h2>Project 1</h2>
            <p>Description of Project 1, showcasing the work done and the results achieved.</p>
          </div>     */}
        </div>
      </div>

      <button onClick={handleSubmit} className="cta-button">Get Your Approval Here</button>
    </div>
  );
};

export default Portfolio;

import React, { useState } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import SuccessModal from '../components/success_modal';
import Navigation from '../components/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import '../styles/home.css';
import logo from '../assets/logo.svg';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Home = () => {
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    address: '',
    phone: '',
    email: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.address.trim()) {
      errors.address = '*';
    } else {
      errors.address = '';
    }

    if (!formData.phone.trim()) {
      errors.phone = '*';
    } else {
      errors.phone = '';
    }

    if (!formData.email.trim()) {
      errors.email = '*';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = '*';
    } else {
      errors.email = '';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const validateFormSubmit = () => {
    const errors = {};

    if (!formData.address.trim()) {
      errors.address = '*';
    }

    if (!formData.phone.trim()) {
      errors.phone = '*';
    }

    if (!formData.email.trim()) {
      errors.email = '*';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = '*';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    if (!validateFormSubmit()) {
      return;
    }

    try {
      const response = await axios.post('https://api.noosaairbnbapprovals.com.au/send-email', formData);

      if (response.status === 200) {
        setShowModal(true);
        setFormData({ address: '', phone: '', email: '' });

        setTimeout(() => {
          navigate("/management");
        }, 20000);
      } else {
        setFormErrors({ form: 'Failed to send data. Please try again later.' });
      }
    } catch (error) {
      console.error('Error sending data:', error);
      setFormErrors({ form: 'An error occurred. Please try again.' });
    }
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {formErrors.form && <p className="form-error">{formErrors.form}</p>}

        {isLoaded && (
          <div className="input-container">
            <FontAwesomeIcon icon={faSearch} className="input-icon" />
            
            <Autocomplete
              onPlaceSelected={(place) => {
                setFormData((prev) => ({
                  ...prev,
                  address: place.formatted_address,
                }))

                validateForm()
              }}
            >
              <input
                type="text"
                placeholder="Address"
                value={formData.address}
                onChange={(e) => {
                  validateForm()

                  setFormData((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }}
              />
            </Autocomplete>

            {formErrors.address && <p className="tooltip">{formErrors.address}</p>}
          </div>
        )}

        <div className="input-container">
          <FontAwesomeIcon icon={faPhone} className="input-icon" />
          <input
            type="tel"
            placeholder="Phone"
            value={formData.phone}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                phone: e.target.value,
              }))

              validateForm()
            }}
          />

          {formErrors.phone && <p className="tooltip">{formErrors.phone}</p>}
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faAt} className="input-icon" />
          
          <input
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                email: e.target.value,
              }))

              validateForm()
            }}
          />

          {formErrors.email && <p className="tooltip">{formErrors.email}</p>}
        </div>

        <button type="submit">Get Your Approval </button>
      </form>

      <div className="info">
        <p>The fast track to Attaining approval to short term holiday let your Noosa Property.</p>
        <br/>
        <p>We're Noosa's only STA approval specialists</p>
        <br/>
        <p>Guaranteed to cost less than any local law consultant or firm.</p>
        <br/>
        <p>Applications completed & submitted within 24hrs of initial consultation.</p>

        <div className="pricing">
          <p>Fixed price approvals</p>
          <p>Unit $1295.95</p>
          <p>House $1695.95</p>
        </div>
        <div className="licence">inc council application fee & first year’s licence fee</div>
      </div>

      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Home;
import React from "react";
import Navigation from "../components/navigation";
import "../styles/prices.css";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Prices = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <div className="prices-content">
        <p style={{ textAlign: "center" }}>
          {" "}
          <strong>Fixed Price Approvals</strong>
        </p>

        <p style={{ textAlign: "center" }}>
          Unit $1295.95
          <br />
          House $1695.95
        </p>
        <p
          style={{
            fontSize: "10px",
            textAlign: "center",
            marginBottom: "20px",
          }}
          className="note"
        >
          * Inc. council application fee & first year's licence fee
        </p>
      </div>

      <div className="prices-content">
        <p>
          {" "}
          <strong>
            We Waive Our Approval fee's for clients who put theyre property
            under management with us
          </strong>{" "}
        </p>
        <p>
          your're council fees may also be paid in arrears with the revenue
          generated by the property.
        </p>
      </div>

      <div className="prices-content">
        <p>
          {" "}
          <strong>Want to Manage Your Property Yourself </strong>{" "}
        </p>
        <p>
          We are a partner of the Airbnb affiliate Program. If it is your first
          property to be listed on airbnb, airbnb will pay us a referral fee
          which will cover the cost of most approvals + your first years license
          and council fees.
        </p>
      </div>

      <button onClick={handleSubmit} className="cta-button">
        Get Your Approval Here
      </button>
    </div>
  );
};

export default Prices;

import React from "react";
import Navigation from "../components/navigation";
import "../styles/management.css";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Management = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  const handleDownload = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/management.pdf`;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Management_Guide.pdf";
    link.click();
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <div className="management-header">
        <h1>Management Resources</h1>
      </div>

      <div className="management-content">
        <p>Access important management resources and guidelines here.</p>
        <button onClick={handleDownload} className="download-button">
          Download PDF
        </button>
      </div>

      <div className="online-training">
        <h2>Online Training Resources</h2>
        <p>
          Explore a curated list of online training modules designed to help you
          enhance your management skills. These resources cover topics such as
          leadership, team management, and strategic planning.
        </p>
        <a
          href="https://www.example.com/training"
          target="_blank"
          rel="noopener noreferrer"
          className="training-link"
        >
          Access Training Resources
        </a>
      </div>

      <button onClick={handleSubmit} className="cta-button">
        Get Your Approval Here
      </button>
    </div>
  );
};

export default Management;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home'
import About from './pages/about';
import Prices from './pages/prices';
import Contact from './pages/contact';
import Management from './pages/management';
import Portfolio from './pages/portfolio';
import Navigation from './components/navigation';
import './styles/app.css';

const App = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/prices" element={<Prices />} /> 
        <Route path="/contact" element={<Contact />} />
          <Route path="/management" element={<Management />} />
          <Route path="/portfolio" element={<Portfolio />} />  
        </Routes>
        <Navigation />
      </div>
    </Router>
  );
};

export default App;
import React from "react";
import Navigation from "../components/navigation";
import "../styles/about.css";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <div className="about-content">
        <p>
          <strong>
            We were born in the prospect of paying $3000+ to attain council
            approval.
          </strong>
        </p>

        <p>
          As you may already be aware, Noosa is a unique playing field when it
          comes to holiday letting, with extremely strict local law &
          regulations in comparison to other holiday destinations, it can be
          hard to know where to start.
        </p>

        <p>
          <strong>"To hard" & "seemingly impossible"</strong>
        </p>
        
        <p>
          Are some of the things that we often hear from property owners when
          asked about their thoughts on holiday letting in Noosa. The Noosa
          council has adopted a strong stance in opposition to short term
          holiday letting & they have done a good job of making it seem
          impossible.
        </p>

        <p>
          <strong>We provide a no approval no fee</strong>
        </p>

        <p>Guarantee, taking the fear & uncertainty out of the process.</p>

        <p>
          All that is required from the property owner is the basic information
          regarding ownership, a signature & we'll take it from there.
        </p>

        <p>
          <strong>
            Noosa’s Only Short Term Accommodation Council Approval Specialists
          </strong>
        </p>

        <p>
          As Noosa’s only short term accommodation approval & management
          specialists, we provide a unique opportunity to property owners &
          investors alike.
        </p>

        <p>
          May it be your holiday home or investment property, we will help you
          attain your short term holiday letting permission for your Noosa
          property & we also provide a management service.
        </p>
      </div>

      <button onClick={handleSubmit} className="cta-button">
        Get Your Approval Here
      </button>
    </div>
  );
};

export default About;


import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/success_modal.css';

const SuccessModal = ({ onClose }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
      navigate('/management');
    }, 2000000000);

    return () => clearTimeout(timer);
  }, [navigate, onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        
        <div className="modal-text">
          <p>Thanks for submitting your information</p>
          <p>we will be in contact within 24 hours with</p>
          <p>the next steps for gaining your approval.</p>
          
          <p  style={{ marginTop: '30px',marginBottom:'-10px'}}>Please note that our office hours are:</p>

          <div className="office-hours">
            <p>Monday - Friday: 8am - 6pm</p>
            <p>Weekends by appointment only</p>
          </div>
          
          <div className="contact-info">
            <p>Contact information:</p>
            <p>0479 088 029</p>
            <p>office@noosaairbnbapprovals.com.au</p>
          </div>
          
          <div className="management-section">
            <h3>Need your property managed?</h3>
            <p>See what sets our management service</p>
            <p>apart from the competition.</p>
            
            <button 
              className="management-button"
              onClick={() => {
                onClose();
                navigate('/management');
              }}
            >
              Management
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;